import * as React from "react";

function SocialLinks() {
  return (
    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
      <span style={{}}>
        <a
          href="https://dddeurope.com"
          target="_blank"
          rel="noreferrer"
          className="mr-4"
        >
          <i className="fa fa-home mr-1" />
          Home
        </a>
      </span>
      <span style={{}}>
        <a
          href="https://twitter.com/ddd_eu"
          target="_blank"
          rel="noreferrer"
          className="mr-4"
        >
          <i className="fab fa-twitter mr-1" />
          Twitter
        </a>
      </span>
      <span style={{}}>
        <a
          href="https://www.linkedin.com/company/domain-driven-design-europe"
          target="_blank"
          rel="noreferrer"
          className="mr-4"
        >
          <i className="fab fa-linkedin mr-1" />
          LinkedIn
        </a>
      </span>
      <span style={{}}>
        <a
          href="https://dddeurope.com/videos/"
          target="_blank"
          rel="noreferrer"
          className="mr-4"
        >
          <i className="fab fa-youtube mr-1" />
          Videos
        </a>
      </span>
      <span style={{}}>
        <a
          href="https://dddeurope.com/mailinglist/"
          target="_blank"
          rel="noreferrer"
          className="mr-4"
        >
          <i className="fa fa-envelope mr-1" />
          Mailing List
        </a>
      </span>
      <span style={{}}>
        <a
          href="https://dddeurope.com/15years/"
          target="_blank"
          rel="noreferrer"
          className="mr-4"
        >
          <i className="fa fa-book mr-1" />
          Anthology
        </a>
      </span>
    </div>
  );
}


export default SocialLinks;
