import * as React from "react";
import events from "../../content/events.yaml"
import Event from "../components/Event"




function Events() {
    return <section className="section">
        <div className="container">
            <div className="columns is-multiline">
                {events.map((event, index) =>
                    <Event event={event} key={`event_${index}`}/>
                )}
            </div>
        </div>
    </section>;
}


export default Events
