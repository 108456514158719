import * as React from "react";
import { graphql, Link } from "gatsby";
import { idify, speakerNames, speakerPictureThumb } from "../../api";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import _ from "lodash";

const Badge = ({ text }) => {
  return (
    <span className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-medium tw-text-gray-800">
      {text}
    </span>
  );
};

const SessionBlock = ({ format, title, speakers, url, slidesUrl }) => {
  return (
    <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-p-4">
      <Link to={url}>
        <h3 className="tw-text-gray-900 tw-leading-5 tw-font-medium">
          {title}
        </h3>
        <Badge text={format} />
      </Link>
      <div>
        <div className="tw-mt-2 tw-inline-flex tw-justify-center">
          <div className="tw-px-2 tw-inline-flex tw-justify-start tw-flex-grow-0 tw-overflow-x-auto tw-scrollbars-hidden">
            {speakers.slice(0, 3).map((speaker) => {
              return (
                <img
                  className="first:tw-ml-0 tw--ml-2 tw-w-20 tw-h-20 tw-left-2 tw-flex-shrink-0 tw-rounded-full tw-object-cover"
                  src={speakerPictureThumb(speaker)}
                  alt={speaker.fullName}
                  key={speaker.fullName}
                />
              );
            })}
          </div>
        </div>
        <div className="tw-mt-2 tw-text-gray-500 tw-text-sm tw-leading-5">
          {speakerNames(speakers)}
        </div>
      </div>
      <div className="tw-mt-5">
        {slidesUrl ? (
          <a
            href={slidesUrl}
            className="tw-text-sm tw-flex tw-justify-center tw-gap-1 tw-items-center"
          >
            Slides
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="tw-h-4 tw-w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        ) : null}
      </div>
    </div>
  );
};

const ProgramBlock = ({ sessions }) => {
  return (
    <div className="tw-mt-8 tw-p-4 tw-rounded-lg">
      <ul className="tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
        {_.map(sessions, (session) => (
          <li
            key={session.id}
            className="tw-col-span-1 tw-flex tw-flex-col tw-text-center tw-bg-gray-100 tw-rounded-lg tw-shadow-md"
          >
            <SessionBlock
              format={session.category_session_format}
              title={session.title}
              speakers={session.speakers}
              url={`/program/${idify(session.title)}`}
              slidesUrl={session.question_slides_url_}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const SessionPage = ({ data }) => {
  let sessions = data.allProgramYaml.nodes[0].sessions;
  sessions = _.sortBy(sessions, (session) => session.title);
  const preconfs = sessions.filter((session) =>
    [
      "Pre-conference Workshop (1 day)",
      "Pre-conference Workshop (2 days)",
      "Pre-conference Workshop (3 days)",
    ].includes(session.category_session_format)
  );
  const mainconf = sessions.filter((session) =>
    [
      "Talk (50m)",
      "Hands-on (2h)",
      // "Short Hands-on (50m)",
      // "Lightning Talk (15m)",
      // "Sponsored Talk",
    ].includes(session.category_session_format)
  );
  const foundations = sessions.filter((session) =>
    ["DDD Foundations Talk (25m)", "DDD Foundations Talk (50m)"].includes(
      session.category_session_format
    )
  );

  return (
    <div>
      <title>DDD Europe 2022 - Program</title>
      <NavBar />

      <div className="tw-bg-ddd-gray">
        <main className="tw-max-w-7xl tw-py-12 tw-mx-auto tw-text-center">
          <h1 className="tw-text-2xl tw-text-ddd-yellow">Program</h1>

          <div key="Pre-Conference Workshops" className="tw-my-8">
            <h2 className="tw-text-xl tw-text-ddd-yellow" id="workshops">
              Pre-Conference Workshops
            </h2>
            <h3 className="tw-text-l tw-text-ddd-yellow">June 20-22</h3>
            <p>
              <a
                className="tw-text-ddd-blue hover:tw-text-ddd-yellow"
                href="https://ti.to/dddbv/dddeu22"
              >
                Tickets
              </a>
            </p>
            <p>
              <a
                className="tw-text-ddd-blue hover:tw-text-ddd-yellow"
                href="https://dddeurope.academy"
              >
                Discover more workshops at DDD Europe Academy
              </a>
            </p>
            <ProgramBlock sessions={preconfs} />
          </div>

          <div key="Pre-Conference Workshops" className="tw-my-8">
            <h2 className="tw-text-xl tw-text-ddd-yellow" id="foundations">
              DDD Foundations
            </h2>
            <h3 className="tw-text-l tw-text-ddd-yellow">June 22</h3>
            <p>
              <a
                className="tw-text-ddd-blue hover:tw-text-ddd-yellow"
                href="https://ti.to/dddbv/dddeu22"
              >
                Tickets
              </a>
            </p>
            <ProgramBlock sessions={foundations} />
          </div>

          <div key="Pre-Conference Workshops" className="tw-my-8">
            <h2 className="tw-text-xl tw-text-ddd-yellow" id="main">
              Main Conference
            </h2>
            <h3 className="tw-text-l tw-text-ddd-yellow">June 23-24</h3>
            <p>
              <a
                className="tw-text-ddd-blue hover:tw-text-ddd-yellow"
                href="https://ti.to/dddbv/dddeu22"
              >
                Tickets
              </a>
            </p>
            <ProgramBlock sessions={mainconf} />
          </div>
        </main>
      </div>

      <Footer />
    </div>
  );
};
export const query = graphql`
  query MyQuery {
    allProgramYaml {
      nodes {
        id
        sessions {
          id
          title
          question_workshoplink
          question_slides_url_
          speakers {
            id
            fullName
            profilePicture
            question_cloudinary_photo_url
          }
          category_session_format
        }
      }
    }
  }
`;
export default SessionPage;
