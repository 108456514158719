import * as React from "react"
import NavBar from "../components/NavBar"
import Hero from "../components/Hero"
import Footer from "../components/Footer"
import Events from "../components/Events"
import MailingList from "../components/MailingList"
import Sponsors from "../components/Sponsors"
import "./style.scss"


function MailingListHero() {
    return <div className="container is-max-desktop">
        <section className="hero is-primary ">
            <div className="hero-body">
                <div className="">
                    <div className=" has-text-centered">
                        <p className="title">
                            Join the Mailing List
                        </p>
                        <MailingList/>
                    </div>
                </div>
            </div>
        </section>
    </div>;
}

const IndexPage = () => {
    return (
        <main>
            <title>DDD Europe 2022 - Software Modelling & Design Conference</title>
            <NavBar/>
            <Hero/>
            <Events/>
            <MailingListHero/>
            <br/>
            <Sponsors/>
            <Footer/>
        </main>)
}

export default IndexPage
