import * as React from "react";

function MarkdownText(props) {
    return <div className="container is-max-desktop mb-6">
        <div className="card">
            <div className="card-content">
                <div className="content" dangerouslySetInnerHTML={{__html: props.file.childMarkdownRemark.html}}/>
            </div>
        </div>
    </div>;
}

export default MarkdownText