import * as React from "react";

function MailingList() {
    return <div>
        <form action="https://dddeurope.us12.list-manage.com/subscribe/post?u=af149f36187bc3bd59fd89129&amp;id=534133777c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate="">
            <div className="field is-grouped" style={{flexWrap: "wrap", justifyContent: "center", gap: "10px"}}>
                {/*<label className="label" htmlFor="mce-EMAIL"/>*/}
                <div className="control">
                    <input className="input email" type="email" name="EMAIL" id="mce-EMAIL" placeholder="Your Email" />
                </div>
                <div className="control">
                    <input className="button is-link" type="submit" value="Subscribe" name="subscribe" id="btn-form-register mc-embedded-subscribe"/>
                </div>
            </div>
            <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                <input type="text" name="b_af149f36187bc3bd59fd89129_534133777c" tabIndex="-1" defaultValue=""/>
            </div>
        </form>
    </div>;
}

export default MailingList
