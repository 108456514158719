import * as React from "react";
import SocialLinks from "../components/SocialLinks"


function Hero() {
    return <div>
        <figure className="image">
            <img src="https://res.cloudinary.com/value-object/image/upload/c_scale,w_2688/v1615555771/DDDEUTR/DDDEUTR_logo_2021.png"
                 alt="DDD Europe"
                 className="logo"
                 style={{"marginBottom": "20px"}}
            />
        </figure>
        <section className="hero is-dark">
            <div className="hero-body has-text-centered	">
                <div className="container">

                    <h1 className="title has-text-weight-light is-size-4 ">
                        Domain-Driven Design Europe 2022
                    </h1>
                    <h2 className="subtitle has-text-weight-light is-size-5">
                        Software Modelling & Design Conference
                    </h2>
                    <h2 className="subtitle has-text-weight-light is-size-5">
                        Meervaart Theater, Amsterdam, June 20-24 2022
                    </h2>
                    <p>
                        <a href="https://ti.to/dddbv/dddeu22" className="button is-large is-primary">Register</a>
                    </p>
                    <br/>
                    <SocialLinks/>
                </div>
            </div>
        </section>
    </div>;
}

export default Hero
