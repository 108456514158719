import React, {useState} from "react";
import 'bulma/css/bulma.min.css';
import { Navbar } from 'react-bulma-components';

const MyNavBar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false)
    const handleToggle = () => {
        setNavbarOpen(prev => !prev)
    }
    return <section>

        <Navbar fixed="top" transparent color="dark" active={navbarOpen}>
            <Navbar.Brand>
                <Navbar.Item renderAs="a" href="https://dddeurope.com/">
                    <img
                        src="https://res.cloudinary.com/value-object/image/upload/c_scale,h_28/v1556449456/Logos/DDDEU-logo-orange-horizontal.png"
                        alt="DDD Europe"
                        width="112"
                        height="28"
                    />
                </Navbar.Item>
                <Navbar.Burger onClick={handleToggle} />
            </Navbar.Brand>
            <Navbar.Menu>
                <Navbar.Container>
                    {/*<Navbar.Item dropdown hoverable href="#">*/}
                    {/*    <Navbar.Link>First</Navbar.Link>*/}
                    {/*    <Navbar.Dropdown>*/}
                    {/*        <Navbar.Item href="#">Subitem 1</Navbar.Item>*/}
                    {/*        <Navbar.Item href="#">Subitem 2</Navbar.Item>*/}
                    {/*    </Navbar.Dropdown>*/}
                    {/*</Navbar.Item>*/}
                    <Navbar.Item href="/" hoverable>Home</Navbar.Item>
                    {/*<Navbar.Item href="/cfp" hoverable>Call for Proposals</Navbar.Item>*/}
                    <Navbar.Item href="/program" hoverable>Program</Navbar.Item>
                    <Navbar.Item href="/schedule" hoverable>Schedule</Navbar.Item>
                    <Navbar.Item href="/program" hoverable>Workshops</Navbar.Item>
                    <Navbar.Item href="/faq" hoverable>FAQ</Navbar.Item>
                    <Navbar.Item href="/values" hoverable>Values</Navbar.Item>
                    <Navbar.Item href="/#sponsors" hoverable>Sponsors</Navbar.Item>
                </Navbar.Container>
                <Navbar.Container position="end">
                    <Navbar.Item href="https://ti.to/dddbv/dddeu22"><i className="fas fa-ticket-alt"/><span>&nbsp;</span>Tickets</Navbar.Item>
                    <Navbar.Item href="mailto:contact@dddeurope.com">Contact</Navbar.Item>
                </Navbar.Container>
            </Navbar.Menu>
        </Navbar>
    </section>
}

export default MyNavBar
