import * as React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";


// markup
const NotFoundPage = () => {
  return (
      <main>
        <title>DDD Europe 2022 - 404 - Software Modelling & Design Conference</title>
        <NavBar/>

        <div className="container is-max-desktop">
          <div className="card">
            <div className="card-content">
              <h1>4040 - Page not found</h1>
                <h2><a href="/">Go Back</a></h2>
            </div>
          </div>
        </div>


        <Footer/>
      </main>
  )
}

export default NotFoundPage
