import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { idify, speakerNames, speakerPictureThumb } from "../api";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Dt = ({ children }) => {
  return (
    <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">
      {children}
    </dt>
  );
};

const Dd = ({ children }) => {
  return (
    <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2 tw-prose">
      {children}
    </dd>
  );
};

const Session = ({ data, pageContext }) => {
  const session = data.allProgramYaml.nodes[0].sessions.find(
    ({ title }) => idify(title) === pageContext.slug
  );
  const { title, speakers, description } = session;

  return (
    <div>
      <title>DDD Europe 2022 - Program</title>
      <NavBar />
      <div className="tw-bg-ddd-gray">
        <main className="tw-max-w-7xl tw-py-12 tw-mx-auto">
          <div className="tw-px-4 tw-py-5 sm:tw-px-6 sm:tw-flex tw-justify-between tw-border-b tw-border-gray-200">
            <div>
              <h1 className="tw-text-2xl tw-text-ddd-yellow tw-text-center">
                {title}
              </h1>
              <div className="tw-bg-white tw-rounded tw-px-4 tw-py-5 sm:tw-p-0 tw-mt-10">
                <dl>
                  <div className="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6 sm:tw-py-5">
                    <Dt>Speakers</Dt>
                    <Dd>
                      <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <p>{speakerNames(speakers)}</p>
                        <div className="tw-flex tw-flex-shrink-0">
                          {speakers.map((speaker) => {
                            return (
                              <img
                                className="first:tw-ml-0 tw--ml-2 tw-rounded-full tw-border-gray-200 tw-border-2 tw-w-16 tw-h-16 tw-object-cover"
                                src={speakerPictureThumb(speaker)}
                                alt={speaker.fullName}
                                key={speaker.id}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </Dd>
                  </div>

                  {null !== session.question_workshoplink && (
                    <div className="tw-mt-8 sm:tw-mt-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-border-t sm:tw-border-gray-200 sm:tw-px-6 sm:tw-py-5">
                      <Dt>Tickets</Dt>
                      <Dd>
                        <a href="https://ti.to/dddbv/dddeu22">
                          Get your pre-conference workshop tickets
                        </a>
                      </Dd>
                    </div>
                  )}

                  {session.question_slides_url_ ? (
                    <div className="tw-mt-8 sm:tw-mt-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-border-t sm:tw-border-gray-200 sm:tw-px-6 sm:tw-py-5">
                      <Dt></Dt>
                      <Dd>
                        <a
                          className="tw-font-medium tw-text-blue-500 hover:tw-text-blue-700"
                          href={session.question_slides_url_}
                        >
                          Download slides
                        </a>
                      </Dd>
                    </div>
                  ) : null}

                  <div className="tw-mt-8 sm:tw-mt-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-border-t sm:tw-border-gray-200 sm:tw-px-6 sm:tw-py-5">
                    <Dt>Description</Dt>
                    <Dd>
                      <MDXRenderer>{description}</MDXRenderer>
                    </Dd>
                  </div>

                  {session.question_handson_has_prerequisites == "true" && (
                    <div className="tw-mt-8 sm:tw-mt-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-border-t sm:tw-border-gray-200 sm:tw-px-6 sm:tw-py-5">
                      <Dt>Hands-on prerequisites</Dt>
                      <Dd>
                        <MDXRenderer>
                          {session.question_handson_prerequisites_description}
                        </MDXRenderer>
                      </Dd>
                    </div>
                  )}

                  {speakers.map((speaker) => {
                    const { fullName, id, bio, tagLine, links } = speaker;
                    return (
                      <div
                        key={id}
                        className="tw-mt-8 sm:tw-mt-0 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-border-t sm:tw-border-gray-200 sm:tw-px-6 sm:tw-py-5"
                      >
                        <Dt>
                          <div className="tw-flex tw-flex-col-reverse sm:tw-flex-row tw-justify-between tw-gap-4">
                            About {fullName}
                            <img
                              className="first:tw-ml-0 tw--ml-2 tw-rounded-md tw-w-full sm:tw-w-32 tw-h-64 sm:tw-h-32 tw-object-cover tw-self-center sm:tw-self-start"
                              src={speakerPictureThumb(speaker)}
                            />
                          </div>
                        </Dt>
                        <Dd>
                          <p className="tw-font-semibold tw-text-gray-600 tw-flex tw-flex-col tw-gap-1">
                            {tagLine}
                            <span className="tw-flex tw-gap-2 tw-p-0">
                              {links.map(({ linkType, title, url }) => {
                                return (
                                  <a
                                    className="tw-text-xs tw-text-gray-700 tw-no-underline tw-font-base"
                                    href={url}
                                    target="_blank"
                                  >
                                    {title}
                                  </a>
                                );
                              })}
                            </span>
                          </p>
                          <span>
                            <MDXRenderer>{bio}</MDXRenderer>
                          </span>
                        </Dd>
                      </div>
                    );
                  })}
                </dl>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Session;

export const query = graphql`
  {
    allProgramYaml {
      nodes {
        sessions {
          id
          title
          description
          question_workshoplink
          question_handson_has_prerequisites
          question_handson_prerequisites_description
          question_slides_url_
          speakers {
            id
            fullName
            profilePicture
            question_cloudinary_photo_url
            bio
            tagLine
            links {
              linkType
              title
              url
            }
          }
        }
      }
    }
  }
`;
