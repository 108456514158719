import * as React from "react";

function Button(props) {
    return <a href={props.url} className="card-footer-item has-background-info has-text-light">
                                {/*
                                <span className="icon">
                                <i className="fas fa-info-circle"/>
                                </span>
                                */}
        {props.label}
    </a>;
}

function Event(props) {

    return <div className="column is-one-third">
        <a id={props.event.id} />
        <div className="card card-rounded is-clipped is-equal-height">
            <a href={props.event.url}>
                {props.event.imageUrl &&
                <div className="card-image">
                    <figure className="image is-16by9">
                        <img src={props.event.imageUrl}
                             alt={props.event.title}/>
                    </figure>
                </div>
                }
                <div className="card-content is-outlined">
                    <div className="media">
                        <div className="media-content">
                            <p className="title is-5 has-text-weight-light">
                                {props.event.title}
                            </p>
                            <p className="subtitle is-6">
                                {props.event.subtitle}
                            </p>
                            <p className="is-6 has-text-black">
                                {props.event.body}
                            </p>
                            {props.event.footer &&
                            <p className="is-6 has-text-grey mt-2 is-italic">
                                {props.event.footer}
                            </p>
                            }
                        </div>
                    </div>
                </div>
            </a>


            <footer style={{height: "72px"}} className="card-footer">

                {props.event.buttons.map((button, index) =>
                    <Button url={button.url} label={button.label} key={`event_${index}`}/>
                )}
            </footer>
        </div>
    </div>

}

export default Event
