import * as React from "react"
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import {graphql} from "gatsby";
import MarkdownText from "../components/MarkdownText";


export const query = graphql`query fetchCoc {
    file (relativePath: {eq: "coc.md"}) {
        childMarkdownRemark {
            frontmatter{title}
            html
        }
    }
}
`



const COCPage = ({data}) => {
    return (
        <main>
            <title>DDD Europe 2022 - {data.file.childMarkdownRemark.frontmatter.title} - Software Modelling & Design Conference</title>
            <NavBar/>


            <section className="hero is-dark">
                <div className="hero-body has-text-centered	">
                    <div className="container">
                        <h1 className="title has-text-weight-light is-size-4 ">
                            Code of Conduct (CoC)
                        </h1>
                    </div>
                </div>
            </section>

            <MarkdownText file={data.file}/>


            <Footer/>
        </main>
    )
        ;
}

export default COCPage