import * as React from "react"
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import {graphql} from "gatsby";
import MarkdownText from "../components/MarkdownText";


export const query = graphql`query fetchSustainability {
    file (relativePath: {eq: "sustainability.md"}) {
        childMarkdownRemark {
            frontmatter{title}
            html
        }
    }
}
`



const Sustainability = ({data}) => {
    return (
        <main>
            <title>DDD Europe 2022 - {data.file.childMarkdownRemark.frontmatter.title} - Software Modelling & Design Conference</title>
            <NavBar/>

            <section className="hero is-dark">
                <div className="hero-body has-text-centered	">
                    <div className="container">
                        <h1 className="title has-text-weight-light is-size-4 ">
                            Sustainability
                        </h1>
                        <p>
                            <a href="mailto:contact@dddeurope.com" className="button is-medium is-primary">Contact us</a>
                        </p>
                    </div>
                </div>
            </section>

            <MarkdownText file={data.file}/>


            <Footer/>
        </main>
    )
        ;
}

export default Sustainability