import slugify from "slugify";

export function idify(name) {
  return slugify(name, { lower: true });
}
export const speakerNames = (speakers) => {
  const names = speakers.map(({ fullName }) => fullName);
  return new Intl.ListFormat("en-GB", {
    style: "long",
    type: "conjunction",
  }).format(names);
};

export const speakerPictureThumb = (speaker) => {
  if (!!speaker.question_cloudinary_photo_url) {
    return speaker.question_cloudinary_photo_url.replace(
      "https://res.cloudinary.com/value-object/image/upload/",
      "https://res.cloudinary.com/value-object/image/upload/c_scale,w_400,t_speakersdddeu21/"
    );
  }
  return speaker.profilePicture;
};
