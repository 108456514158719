import * as React from "react";
import SocialLinks from "../components/SocialLinks"

function Footer() {
    return <footer className="footer">
        <div className="content has-text-centered">
            <p>
                <a href="/terms">Terms & Conditions</a>
            </p>
            <p>
                <a href="/covid">COVID-19 Policy</a>
            </p>
            <p>
                <SocialLinks/>
            </p>
            <p>
                <a href="mailto:contact@dddeurope.com" className="mr-4">contact@dddeurope.com</a>
                <a href="https://twitter.com/ddd_eu" className="mr-4">@ddd_eu</a>
                Tel <span className="phonenumber">+32 468 109 891</span>
            </p>
            <p>
                © 2015-2022 Aardling BV. All rights reserved.
            </p>
        </div>
    </footer>;
}

export default Footer

