import * as React from "react"
import NavBar from "../components/NavBar";
import faq from "../../content/faq.yaml";
import Footer from "../components/Footer";

const Faq = () => {
    return (
        <main>
            <title>DDD Europe 2022 - FAQ - Software Modelling & Design Conference</title>
            <NavBar/>

            <section className="hero is-dark">
                <div className="hero-body has-text-centered	">
                    <div className="container">
                        <h1 className="title has-text-weight-light is-size-4 ">
                            Frequently Asked Questions
                        </h1>
                    </div>
                </div>
            </section>

            <div className="container is-max-desktop mb-6">
                <div className="card">
                    <div className="card-content">
                        <div className="content">
                            {faq.categories.map((category, index) =>
                                <div className="mb-5">
                                    <h1 className="mt-5">{category.title}</h1>
                                    {category.items.map((item, index) =>
                                        <div className="mb-4">
                                            <p dangerouslySetInnerHTML={{__html: "<strong>"+item.question+"</strong><br/>"+item.answer}}/>
                                        </div>
                                    )}
                                    <br/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            <Footer/>
        </main>
    )
        ;
}

export default Faq