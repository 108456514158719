import * as React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const Schedule = () => {
  return (
    <div>
      <title>DDD Europe 2022 - Schedule</title>
      <NavBar />

      <div className="tw-bg-ddd-gray">
        <main className="sm:tw-mx-4 tw-text-center">
          <iframe frameBorder="0" className="tw-h-[1200px] tw-w-full" src="https://sessionize.com/view/20bm82gg/GridSmart?format=Embed_Vanilla_Html&isDark=False&title=Domain-Driven%20Design%20Europe%202022"></iframe>
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default Schedule;
