import * as React from "react";
import sponsors from "../../content/sponsors.yaml"
import * as PropTypes from "prop-types";

function Sponsor(props) {
    return <div className={"column " + props.column}>
        <div>
            <a id={props.sponsor.id}/>
            <a href={props.sponsor.url} target={"_blank"}>
                <figure className="image">
                    <img src={props.sponsor.logo} className="button"
                         alt={props.sponsor.name}/>
                    <figcaption className="mt-4">{props.sponsor.tagline}</figcaption>
                </figure>
            </a>
        </div>
    </div>;
}

Sponsor.propTypes = {
    column: PropTypes.string,
    sponsor: PropTypes.any
};

function Sponsors() {
    return <section className="hero is-light">
        <a id="sponsors"/>
        <div className="hero-body has-text-centered	">
            <div className="container">

                <h1 className="title has-text-weight-light is-size-4 ">Platinum sponsor</h1>
                <div className="columns">
                    <Sponsor sponsor={sponsors.eventstore} key={sponsors.eventstore.id} column={"is-half is-offset-one-quarter"} />
                </div>

                <br/>

                <h1 className="title has-text-weight-light is-size-4">Gold sponsors</h1>
                <div className="columns">
                    <Sponsor sponsor={sponsors.aardling} key={sponsors.aardling.id} column={"is-one-quarter is-offset-one-quarter"} />
                    <Sponsor sponsor={sponsors.casavo} key={sponsors.casavo.id} column={"is-one-quarter"} />
                </div>
                <div className="columns">
                    <Sponsor sponsor={sponsors.xebia} key={sponsors.casavo.id} column={"is-one-quarter is-offset-one-quarter"} />
                    <Sponsor sponsor={sponsors.particular} key={sponsors.particular.id} column={"is-one-quarter"} />
                </div>

                <br/>

                <h1 className="title has-text-weight-light is-size-4 ">Silver sponsors</h1>
                <div className="columns">
                    <Sponsor sponsor={sponsors.innoq} key={sponsors.innoq.id} column={"is-one-fifth is-offset-one-fifth"} />
                    <Sponsor sponsor={sponsors.wps} key={sponsors.wps.id} column={"is-one-fifth"} />
                    <Sponsor sponsor={sponsors.afterman} key={sponsors.afterman.id} column={"is-one-fifth"} />
                </div>

                <br/>

                <h1 className="title has-text-weight-light is-size-4 ">Bronze sponsors</h1>
                <div className="columns">
                    <Sponsor sponsor={sponsors.alcor} key={sponsors.alcor.id} column={"is-2 is-offset-2"} />
                    <Sponsor sponsor={sponsors.arolla} key={sponsors.arolla.id} column={"is-2"} />
                    <Sponsor sponsor={sponsors.acagroup} key={sponsors.acagroup.id} column={"is-2"} />
                    <Sponsor sponsor={sponsors.dreamdev} key={sponsors.dreamdev.id} column={"is-2"} />
                    {/*<Sponsor sponsor={sponsors.yourbrandhere} key={sponsors.yourbrandhere.id} column={"is-2"} />*/}
                </div>

            </div>
        </div>
    </section>
        ;
}


export default Sponsors
