import * as React from "react"
import {graphql} from 'gatsby'
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MarkdownText from "../components/MarkdownText";

export const query = graphql`query fetchShuttle {
    file (relativePath: {eq: "shuttle.md"}) {
        childMarkdownRemark {
            frontmatter{title}
            html
        }
    }
}
`



const Shuttle = ({data}) => {
    return (
        <main>
            <title>DDD Europe 2022 - {data.file.childMarkdownRemark.frontmatter.title} - Software Modelling & Design Conference</title>
            <NavBar/>

            <section className="hero is-dark">
                <div className="hero-body has-text-centered	">
                    <div className="container">
                        <h1 className="title has-text-weight-light is-size-4 ">
                            {data.file.childMarkdownRemark.frontmatter.title}
                        </h1>
                    </div>
                </div>
            </section>

            <MarkdownText file={data.file}/>


            <Footer/>
        </main>
    )
        ;
}

export default Shuttle